<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="form.first_name" label="First Name" outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="form.last_name" label="Last Name" outlined />
          </v-col>
        </v-row>

        <v-select v-model="form.gender" label="Gender" :items="genderOptions" item-text="title" item-value="value" outlined />
        <div>Birth Date:</div>
        <v-date-picker v-model="form.birth_date" label="Birth Date" outlined class="mb-6" />
        <v-text-field v-model="form.email" label="Email" outlined />

        <v-row>
          <v-col cols="4">
            <v-select
              v-model="form.country_code"
              label="Country Code"
              item-text="title"
              item-value="value"
              outlined
              :items="countryCodeOptions"
            ></v-select>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="form.phone" label="Phone" outlined />
          </v-col>
        </v-row>

        <v-select v-model="form.relationship" label="Relationship" :items="relationshipOptions" item-text="title" item-value="value" outlined />
        <div>Activation Date:</div>
        <v-date-picker v-model="form.activated_at" label="Activation Date" outlined class="mb-6" />

        <v-select
            v-model="form.status"
            label="Status"
            item-text="title"
            item-value="value"
            outlined
            :items="statusOptions"
          ></v-select>

        <v-switch label="Main member?" v-model="form.main" />
        <v-switch label="Allow to access other members' card?" v-model="form.access" />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" :loading="loading" @click="validate"> Submit </v-btn>
      </v-card-actions>

      <v-alert v-for="msg in errors" :key="msg" color="warning">
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const parseResource = () => {
      props.resource['activated_at'] = dayjs(props.resource.activated_at).format('YYYY-MM-DD')

      return props.resource
    }

    const formatData = data => {
      data['activated_at'] = dayjs(data.activated_at).utc().local().format()

      return data
    }

    const parsedResource = parseResource()

    const field = (name, initValue) => ({ [name]: parsedResource ? parsedResource[name] : initValue })

    const initialForm = {
      ...field('first_name', null),
      ...field('last_name', null),
      ...field('gender', 'male'),
      ...field('birth_date', null),
      ...field('email', null),
      ...field('country_code', null),
      ...field('phone', null),
      ...field('relationship', null),
      ...field('activated_at', null),
      ...field('main', false),
      ...field('access', false),
      ...field('status', 'inactive'),
    }

    const genderOptions = ref([
      {
        title: 'Male',
        value: 'male',
      },
      {
        title: 'Female',
        value: 'female',
      },
    ])

    const relationshipOptions = ref([
      {
        title: 'Self',
        value: 'self',
      },
      {
        title: 'Spouse',
        value: 'spouse',
      },
      {
        title: 'Parent',
        value: 'parent',
      },
      {
        title: 'Parent In Law',
        value: 'parent-in-law',
      },
      {
        title: 'Child',
        value: 'child',
      },
      {
        title: 'Sibling',
        value: 'sibling',
      },
    ])

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', formatData(form.value))
      }
    }

    const countryCodeOptions = [
      { title: '852', value: '852' },
      { title: '853', value: '853' },
      { title: '86', value: '86' },
    ]

    const statusOptions = [
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
      { title: 'Suspended', value: 'suspended' },
      { title: 'Cancelled', value: 'cancelled' },
      { title: 'Expired', value: 'expired' },
      { title: 'Voided', value: 'voided' },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      genderOptions,
      relationshipOptions,
      countryCodeOptions,
      statusOptions,

      dayjs,
    }
  },
}
</script>
