import axios from '@axios'

const getCards = async params => {
  const { status, data } = await axios.get('/admin/cards', { params })

  if (status === 200) {
    return data
  }

  return null
}

const updateCard = async (id, params) => {
  const { status, data } = await axios.post(`/admin/cards/${id}`, params)

  if (status === 200) {
    return data
  }

  return null
}

const importCards = async params => {
  const { status, data } = await axios.post(`/admin/cards/import`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (status === 200) {
    return data
  }

  return null
}

const fetchPlans = async queryParams => axios.get('/admin/medical/card-plans', { params: queryParams })
const storePlan = async data => axios.post('/admin/medical/card-plans', data)
const updatePlan = async (id, data) => axios.put(`/admin/medical/card-plans/${id}`, data)
const destroyPlan = async id => axios.delete(`/admin/medical/card-plans/${id}`)

const exportCards = async params => {
  const response = await axios.post('/admin/cards/export', params, {
    responseType: 'arraybuffer',
  })

  let blob = new Blob([response.data])
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'medical_cards.xlsx'
  link.click()
}
export { getCards, updateCard, importCards, fetchPlans, storePlan, updatePlan, destroyPlan, exportCards }
