<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="val => $emit('aside', val)"
  >
    <update-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { updateCard } from '@/api/medical/card'
import { useExtractErrors } from '@/composables'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import UpdateForm from './UpdateForm.vue'

export default {
  components: { UpdateForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async formData => {
      loading.value = true

      try {
        const { status } = await updateCard(props.resource.id, formData)

        if (status === 'success') {
          emit('changed')
        }
      } catch (error) {
        errors.value = useExtractErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
