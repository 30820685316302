var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "First Name",
      "outlined": ""
    },
    model: {
      value: _vm.form.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "first_name", $$v);
      },
      expression: "form.first_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Last Name",
      "outlined": ""
    },
    model: {
      value: _vm.form.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "last_name", $$v);
      },
      expression: "form.last_name"
    }
  })], 1)], 1), _c('v-select', {
    attrs: {
      "label": "Gender",
      "items": _vm.genderOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }), _c('div', [_vm._v("Birth Date:")]), _c('v-date-picker', {
    staticClass: "mb-6",
    attrs: {
      "label": "Birth Date",
      "outlined": ""
    },
    model: {
      value: _vm.form.birth_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "birth_date", $$v);
      },
      expression: "form.birth_date"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "outlined": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Country Code",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.countryCodeOptions
    },
    model: {
      value: _vm.form.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "country_code", $$v);
      },
      expression: "form.country_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Phone",
      "outlined": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1)], 1), _c('v-select', {
    attrs: {
      "label": "Relationship",
      "items": _vm.relationshipOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.relationship,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "relationship", $$v);
      },
      expression: "form.relationship"
    }
  }), _c('div', [_vm._v("Activation Date:")]), _c('v-date-picker', {
    staticClass: "mb-6",
    attrs: {
      "label": "Activation Date",
      "outlined": ""
    },
    model: {
      value: _vm.form.activated_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "activated_at", $$v);
      },
      expression: "form.activated_at"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Status",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.statusOptions
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Main member?"
    },
    model: {
      value: _vm.form.main,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "main", $$v);
      },
      expression: "form.main"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Allow to access other members' card?"
    },
    model: {
      value: _vm.form.access,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "access", $$v);
      },
      expression: "form.access"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }