import { useTableOptions } from '@/composables'
import { exportCards, getCards } from '@api/medical/card'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Card Number', value: 'card_number' },
    { text: 'Main User', value: 'main' },
    { text: 'First Name', value: 'first_name' },
    { text: 'Last Name', value: 'last_name' },
    { text: 'Gender', value: 'gender' },
    { text: 'Birth Date', value: 'birth_date' },
    { text: 'Phone', value: 'phone' },
    { text: 'Relationship', value: 'relationship' },
    { text: 'Register Date', value: 'registered_at' },
    { text: 'Activate Date', value: 'activated_at' },
    { text: 'Expiry Date', value: 'expired_at' },

    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    distributor_id: null,
    status: null,
  })

  const search = ref(null)

  const loadCards = async () => {
    loading.value = true

    try {
      const params = {
        ...useTableOptions(options.value, parseFilters()),
        ...(search.value && { search: search.value }),
      }

      const {
        status,
        data: { records, pagination },
      } = await getCards(useTableOptions(options.value, params))

      if (status === 'success') {
        tableItems.value = records
        tableTotal.value = pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const exportToXlsx = async () => {
    loading.value = true

    try {
      await exportCards(parseFilters())
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  watch([options], () => {
    loadCards()
  })

  watch(
    () => filters.distributor_id,
    (newId, oldId) => {
      options.value.page = 1

      loadCards()
    },
    { deep: true },
  )

  watch(
    () => filters.status,
    (newId, oldId) => {
      loadCards()
    },
    { deep: true },
  )

  watch(
    () => search.value,
    debounce(() => {
      options.value.page = 1

      loadCards()
    }, 300),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,
    search,

    loadCards,

    exportToXlsx,
  }
}
